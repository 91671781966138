/* footer */
.footer {
  background-color: $color-blue;
  color: $color-white;

  &__navigation {
    padding: 20px 68px 60px 68px;
    width: 100%;
    float: left;
    text-align: center;
    font-size: 17px;
    &-btn {
      display: none;
      font-size: 24px;
      text-transform: uppercase;
      background: none;
      border: 0;
      span {
        position: relative;
        height: 3px;
        width: 20px;
        display: inline-block;
        margin-right: 10px;
        background: $color-white;
        vertical-align: 7px;
        &::before, &::after {
          content: '';
          width: 20px;
          left: 0;
          position: absolute;
          top: -6px;
          height: 3px;
          background: $color-white; }
        &::after {
          top: 6px; } } }
    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 0;
        display: inline-block;
        &>a, &>span {
          display: block;
          text-decoration: none;
          text-align: center;
          color: $color-white; }
        &>a {
          &:hover, &:focus {
            text-decoration: underline; } }
        &.active {
          &>a, &>span {
            color: #99ccff; } } } } }

  &__bottom {
    position: relative;
    padding-top: 40px;
    padding-bottom: 150px;
    font-size: 16px;
    @include clearfix;
    &-left {
      float: left;
      width: 100%;
      max-width: 405px;
      margin-left: 68px;
      p {
        line-height: 25px; } }
    &-img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
    &-title {
      font-size: 29px;
      margin-bottom: 25px; } }
  &__img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%); }

  &__form {
    float: right;
    width: 100%;
    max-width: 225px;
    margin-right: 165px; }
  &__btn {
    width: 165px;
    text-align: center;
    height: 30px;
    border: 1px solid #fff;
    color: $color-white;
    background: #00adee;
    font-size: 17px;
    // font-weight: bold
    padding: 0;
    &:hover {
      background: $color-white;
      color: #00adee; } }
  &-checkbox {
    position: relative;
    padding-left: 45px;
    font-weight: 400;
    color: #999999;
    font-size: 14px;
    cursor: pointer;
    input {
      display: none;
      &:checked {
        &+span {
          background: #999;
          box-shadow: 0 0 0 1px #fff inset; } }
      &+span {
        display: block;
        position: absolute;
        left: 14px;
        top: 0;
        border: 1px solid #999999;
        background: #fff;
        width: 15px;
        height: 15px; }
      &~.span {
        font-weight: bold; } } } }
