/* header */
.header {
  background: rgba($color-blue,1);
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  color: $color-white;
  box-shadow: 0 0 15px rgba($color-black,.25);
  a {
    color: $color-white; }

  &-separator {
    height: 90px; }
  &__search {
    float: right;
    width: 150px;
    padding-top: 24px;
    margin-right: 30px;
    margin-top: -90px;
    @include clearfix;
    &-input {
      border: 0;
      padding: 100%;
      height: 30px;
      background: #0072bc;
      color: $color-white;
      font-size: 14px;
      padding: 0 40px 0 10px;
      width: 100%; }
    &-btn {
      background: none;
      border: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      float: right;
      margin-top: -30px;
      position: relative;
      .icon {
        width: 14px;
        height: 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAABJklEQVQokY3SMUtcQRAA4O/0AnKSJpUEm2BjFYQFIVGCIoqIlcafYWknCemTFCktRDD9IUkjHKQ4SXUDio0HCvkBIQmBFEnzLG5P3r3cgdNssfvNzO5srSgKEBHr2MMCHqCLQ3xIKf1TiVpRFCLiFd7gL9r4hed4jK9YSyn9KcOxiFjL6ByzKaXVlNIOnuBtTvD+v4qdTqeFxYy+VQ9ExClWMJVS+n5XMWdsD0M5jjGO+YFWMYGfIxD8yOvDKrzGs4ioj4Av8tqtwmNM43VVRMQcdnGJi/JeHe+whf2IeJoT/cZSRg009WZ7N8/+HB/hANuVojf4mLv5hJf9z1Dr/5zc2ozeK0/iCmf5Ok1slPEAHBUR0cBnLOMLNu8Fh+DWvWEJn+DoFhZtamYaTulDAAAAAElFTkSuQmCC') 0 0 no-repeat; } } } }

.navigation {
  padding-right: 195px;
  width: 100%;
  float: left;
  text-align: center;
  font-size: 17px;
  &__btn {
    display: none;
    font-size: 24px;
    text-transform: uppercase;
    background: none;
    border: 0;
    margin-top: 8px;
    float: left;
    span {
      position: relative;
      height: 3px;
      width: 20px;
      display: inline-block;
      margin-right: 10px;
      background: $color-white;
      vertical-align: 7px;
      &::before, &::after {
        content: '';
        width: 20px;
        left: 0;
        position: absolute;
        top: -6px;
        height: 3px;
        background: $color-white; }
      &::after {
        top: 6px; } } }
  ul {
    margin: 0;
    padding: 0;
    &>li {
      list-style: none;
      margin: 0;
      display: inline-block;
      position: relative;
      &:hover {
        &>ul {
          display: block; }
        &>a, &>span {
          background: #0072bc; } }
      &>a, &>span {
        display: block;
        height: 90px;
        line-height: 83px;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        padding: 0 15px; }
      &>a {
        &:hover, &:focus {
          background: #0072bc; } }
      &.active {
        &>a, &>span {
          background: #0072bc; } }
      &>ul {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        width: 200px;
        background: #0072bc;
        &>li {
          display: block;
          &>a, &>span {
            display: block;
            height: auto;
            line-height: normal;
            padding: 10px 15px;
            text-align: left;
            text-transform: none; }
          &.active {
            &>a,&>span {
              background: rgba($color-blue,1); } }
          &>a {
            &:hover, &:focus {
              background: rgba($color-blue,1); } } } } } } }
