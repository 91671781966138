* {
  box-sizing: border-box; }

// Selection colors (easy to forget)
// ::selection
//   background: $selection-color
// ::-moz-selection
//   background: $selection-color
// img::selection
//   background: transparent
// img::-moz-selection
//   background: transparent

body {
 }  // -webkit-tap-highlight-color: $selection-color

// Basic Styles
html {
  font-size: inherit;
  line-height: 1.3;
  font-size: 16px; }

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  min-width: 320px; }

body {
  background: {
    color: #f2f7fc;
    position: center -300px;
    repeat: no-repeat;
    image: url('../img/background.jpg'); }
  font-size: 21px;
  font-family: $primary-font;
  color: $primary-color;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }

//  Typography
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: $primary-color;
  font-weight: bold;
  text-decoration: none;
  margin: 0 0 15px 0; }

.h1, h1 {
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -1px;
  margin-bottom: 50px;
  color: #005297;
  font-weight: bold;
  text-transform: uppercase; }

.h2, h2 {
  font-size: 18px;
  line-height: 1.14;
  margin-bottom: 20px; }

.h3, h3 {
  font-size: 16px;
  line-height: 1.38; }

.h4, h4 {
  font-size: 14px;
  line-height: 1.38; }

.h5, h5 {
  font-size: 13px; }

.h6, h6 {
  font-size: 12px; }

.small, small {
  font-size: 12px;
  line-height: 1.2; }

p {
  line-height: 30px;
  margin: 0 0 20px 0; }

em, i, .em, .i {
  font-style: italic; }

strong, b, .strong, .b {
  font-weight: bold; }

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 10px 0 30px;
  height: 0; }

// Links
a, .link {
  color: $link-color;
  text-decoration: underline;
  outline: none;
  transition: color 0.35s, background 0.35s, border 0.35s;

  &:hover, &:focus, &:active {
    color: $link-color;
    text-decoration: none;
    outline: none; } }

a.link {
  &:hover, &:focus, &:active {
    text-decoration: none; } }

.container {
  margin: 0 auto;
  // width: 1280px
  width: 100%;
  min-width: 320px;
  padding: 0 15px;
  .container {
    min-width: none; } }

/* text align */
.text {
  &-center, &--center {
    text-align: center !important; }
  &-left, &--left {
    text-align: left !important; }
  &-right, &--right {
    text-align: right !important; } }

/* loader */
.loader {
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 10000;
  background-color: $color-white;
  &__block {
    background: $color-blue;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    position: absolute;
    top: 50%;
    left: 50%;
    color: $color-blue;
    text-indent: -9999em;
    font-size: 11px;
    animation-delay: -0.16s;
    transform: translate(-50%,-50%);
    &:before, &:after {
      background: $color-blue;
      animation: load1 1s infinite ease-in-out;
      width: 1em;
      height: 4em; }
    &:before, &:after {
      position: absolute;
      top: 0;
      content: ''; }
    &:before {
      left: -1.5em;
      animation-delay: -0.32s; }
    &:after {
      left: 1.5em; } }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em; }
    40% {
      box-shadow: 0 -2em;
      height: 5em; } }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em; }
    40% {
      box-shadow: 0 -2em;
      height: 5em; } } }

/* img */
.img {
  &-responsive, &--responsive {
    display: block;
    height: auto;
    max-width: 100%; }

  &-round, &--round {
    border-radius: 50%; } }

/* section */
.section {
  padding: 50px 0;
  min-width: 320px; }

.container {
  .section {
    min-width: inherit; } }

ul li {
  list-style-type: disc; }
ol li {
  list-style-type: decimal; }

/* bg */
.bg {
  &--pink {
    background-color: pink !important; }
  &--white {
    background-color: $color-white !important; }
  &--black {
    background-color: $color-black !important; } }

/* no padding no margin */
.no {
  &--p {
    padding: 0 !important;
    &-top {
      padding-top: 0 !important; }
    &-bottom {
      padding-bottom: 0 !important; }
    &-left {
      padding-left: 0 !important; }
    &-right {
      padding-right: 0 !important; } }

  &--m {
    margin: 0 !important;
    &-top {
      margin-top: 0 !important; }
    &-bottom {
      margin-bottom: 0 !important; }
    &-left {
      margin-left: 0 !important; }
    &-right {
      margin-right: 0 !important; } }

  &--b {
    background: none !important;
    &-image {
      background-image: none !important; } }

  &--border {
    border: 0 !important; }

  &--underline {
    text-decoration:  none !important; } }

/* icons */
.icon {
  display: inline-block;
  // &--sprite
 }  //   @include sprite($img-name)

/* color */
.color {
  &--black {
    color: $color-black !important; }
  &--white {
    color: $color-white !important; }
  &--blue {
    color: $color-blue !important; }
  &--red {
    color: $color-red !important; } }
