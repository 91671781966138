/* forms */

input {
  &::-webkit-input-placeholder {
    color: #666666; }
  &::-moz-placeholder {
    color: #666666; }
  &:-ms-input-placeholder {
    color: #666666; }
  &:-moz-placeholder {
    color: #666666; } }

button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  outline: none;
  &:hover, &:focus, &:active {
    outline: none; } }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
  outline: none; }

.form {
  &-group,  &__group {
    margin-bottom: 25px; }

  &-control, &__control {
    display: block;
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #666666;
    background-color: $color-white;
    background-image: none;
    border: 1px solid #0072bc;
    border-radius: 0px;
    box-shadow: inset 0 0 0 1px #bbd9ed;
    transition: all .35s; } }

textarea {
  &.form-control, &.form__control {
    height: 150px;
    resize: none; } }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

.has {
  &-success .form-control {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(#3c763d,.75); }

  &-warning .form-control {
    border-color: #8a6d3b;
    box-shadow: inset 0 1px 1px rgba(#8a6d3b,.75); }

  &-error .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(#a94442,.75); }

  &-success .control-label {
    color: #3c763d; }

  &-warning .control-label {
    color: #8a6d3b; }

  &-error .control-label {
    color: #a94442; } }
