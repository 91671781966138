/* modules */

.content {
  padding-top: 55px;
  padding-bottom: 75px;
  &--all {
    padding-top: 25px; }
  &__top {
    position: relative;
    max-width: 945px;
    min-height: 190px;
    margin: 0 auto 130px;
    &-left {
      position: absolute;
      left: 0;
      top: 0; }
    &-right {
      position: absolute;
      right: 0;
      top: 0; }
    &-title {
      font-size: 83px;
      color: $color-blue;
      text-transform: uppercase;
      line-height: 1;
      padding-left: 220px;
      padding-right: 165px;
      font-weight: bold;
      white-space: nowrap;
      line-height: 90px;
      position: absolute;
      top: 0; }
    &-untitle {
      color: $color-red;
      font-size: 33px;
      line-height: 42px;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      position: absolute;
      top: 200px;
      width: 100%; }
    &--all {
      max-width: 1140px;
      min-height: 140px;
      margin-bottom: 25px;
      .content__top {

        &-left {
          width: 143px; }
        &-right {
          width: 120px; }
        &-title {
          padding-left: 165px;
          padding-right: 120px;
          font-size: 62px;
          br {
            display: none; } }
        &-untitle {
          font-size: 29px;
          top: 80px;
          padding-left: 165px;
          text-align: left;
          br {
            display: none; } } } } }

  &__greeting {
    margin-bottom: 75px;
    position: relative;
    @include clearfix;
    &-text {
      background: $color-white;
      width: 587px;
      float: right;
      box-shadow: 0 0 35px rgba($color-blue,.13);
      min-height: 246px;
      font-style: italic;
      font-weight: 400;
      padding: 20px;
      font-size: 16px;
      p {
        margin: 0;
        line-height: 25px; } }
    &-img1, &-img2 {
      position: absolute;
      left: 0;
      top: 0; }
    &-img2 {
      left: 276px; } }
  &__about {
    &-gallery {
      display: flex;
      margin: 0 -15px 55px -15px;
      &>div {
        padding: 0 15px;
        width: 33.3%; } }
    &-imgs {
      display: flex;
      justify-content: center;
      img {
        max-width: 100%;
        height: auto;
        display: block; } }
    &-title {
      text-align: center;
      color: $color-blue;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: bold;
      max-width: 765px;
      margin: 0 auto 45px; }
    &-text {
      background: $color-white;
      box-shadow: 0 0 35px rgba($color-blue,.13);
      padding: 68px;
      margin-bottom: 40px;
      p {
        &:last-child {
          margin-bottom: 0; } } } }
  &__news {
    background: $color-white;
    box-shadow: 0 0 35px rgba($color-blue,.13);
    padding-bottom: 40px;
    &--list {
      background: rgba($color-white,.25);
      .content__news {
        &-text {
          padding-left: 30px; }
        &-wrapper {
          padding: 40px 75px 10px 75px;
          &>div {
            width: 100%;
            padding-left: 0 !important;
            border-left: 0 !important;
            border-bottom: 1px solid #ccc !important;
            &:last-child {
              border-bottom: 0 !important; } } } } }
    &-title {
      font-size: 29px;
      padding: 40px 65px 20px 65px; }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      &>div {
        width: 50%;
        padding: 13px 13px 13px 0;
        border-bottom: 1px solid #cccccc;
        &:nth-child(2), &:nth-child(4) {
          border-left: 1px solid #cccccc;
          padding-left: 13px; }
        &:last-child, &:nth-last-child(2) {
          border-bottom: 0; } } }
    &-block {
      font-size: 17px;
      display: flex;
      p {
        line-height: 21px;
        &:last-child {
          margin: 0; } } }
    &-img {
      flex-shrink: 0; }
    &-text {
      padding-left: 15px; }
    &-date {
      font-weight: bold;
      font-size: 17px;
      margin-bottom: 23px; } }
  &__wrapper {
    background: rgba($color-white,.25);
    padding-top: 65px;
    padding-bottom: 85px;
    @include clearfix;
    img {
      max-width: 100%;
      height: auto;
      display: block; }
    p {
      text-align: justify; } }
  &__head {
    text-align: center;
    &--document {
      max-width: 600px;
      margin: 0 auto; } }
  &__thumb {
    float: left;
    margin-right: 30px;
    margin-bottom: 20px;
    max-width: 458px;
    &--right {
      float: right;
      margin-right: 0;
      margin-left: 30px; } }
  &__introtext {
    text-align: right;
    color: #336699;
    font-size: 19px;
    font-style: italic;
    margin-bottom: 40px;
    i {
      display: block; } }
  &__gallery {
    display: flex;
    margin-bottom: 15px;
    &>div {
      width: 25%; }
    &--document {
      margin: 0 -15px;
      &>div {
        padding: 0 15px;
        width: 33.3% !important; } } } }
