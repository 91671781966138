/*==========  Desktop First Method  ==========*/

/* 1920 */
@media only screen and (min-width : 1999px) {
  /**/ }

@media only screen and (max-width : 1599px) {
  /**/ }

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1439px) {
  /**/ }

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1199px) {
  body {
    background-size: auto 603px;
    background-position: center -55px; }
  .content {
    &__greeting {
      padding: 0 35px;
      &-img1 {
        left: 35px; }
      &-img2 {
        display: none; }
      &-text {
        width: 593px; } }
    &__news {
      &-img {
        width: 212px; }
      &-title {
        padding-left: 50px;
        padding-right: 50px; }
      &--list {
        .content__news {
          &-wrapper {
            padding-left: 0;
            padding-right: 0; }
          &-img {
            width: 278px; } } } }
    &__about {
      &-text {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 25px; } }
    &__thumb {
      max-width: 374px; }
    &__introtext {
      margin-bottom: 30px; }
    &__top {
      &--all {
        min-height: 117px;
        .content__top {
          &-title {
            font-size: 51px;
            padding-right: 90px;
            padding-left: 135px; }
          &-untitle {
            font-size: 24px;
            top: 75px;
            padding-left: 135px; }
          &-left {
            width: 117px; }
          &-right {
            width: 98px; } } } } }
  .navigation {
    padding-right: 155px;
    ul {
      li {
        &>a, &>span {
          padding: 0 5px; } } } }
  .footer {
    &__navigation {
      padding-left: 0;
      padding-right: 0; }
    &__bottom {
      &-left {
        margin-left: 0; } }
    &__form {
      margin-right: 66px; } }
  .header {
    &__search {
      margin-right: 0; } } }

@media only screen and (max-width : 1109px) {
  .content {
    &__about {
      &-imgs {
        &>div {
          &:first-child, &:last-child {
            display: none; } } } } } }

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
  body {
    background-size: auto 479px;
    background-position: center 10px; }
  .content {
    &__top {
      min-height: 140px;
      margin-bottom: 102px;
      &-left {
        width: 146px;
        left: 8px; }
      &-right {
        right: 8px;
        width: 125px; }
      &-title {
        padding-left: 178px;
        padding-right: 130px;
        font-size: 61px;
        line-height: 68px; }
      &-untitle {
        font-size: 24px;
        top: 148px;
        line-height: 31px; }
      &--all {
        min-height: 90px;
        margin-bottom: 50px;
        .content__top {
          &-title {
            font-size: 38px;
            padding-right: 75px;
            padding-left: 105px; }
          &-untitle {
            font-size: 18px;
            top: 60px;
            padding-left: 105px; }
          &-left {
            width: 90px; }
          &-right {
            width: 75px; } } } }
    &__greeting {
      margin-bottom: 65px;
      &-img1 {
        width: 232px; }
      &-text {
        width: calc(100% - 232px); } }
    &__news {
      &-wrapper {
        &>div {
          width: 100%;
          &:nth-child(2), &:nth-child(4) {
            padding-left: 0;
            border-left: 0; }
          &:nth-last-child(2) {
            border-bottom: 1px solid #ccc; } } }
      &--list {
        .content__news {
          &-wrapper {
            padding-top: 28px; }
          &-img {
            width: 220px; } } } }
    &__thumb {
      max-width: 283px; }
    &__wrapper {
      padding-bottom: 55px; }
    &__gallery {
      flex-wrap: wrap;
      &>div {
        width: 50%; } } }
  .header {
    height: 50px;
    &-separator {
      height: 50px; }
    &__search {
      width: 125px;
      margin-top: -66px; } }
  .navigation {
    font-size: 13px;
    padding-right: 125px;
    ul {
      li {
        &>a, &>span {
          // padding: 0
          height: 50px;
          line-height: 50px; } } } }
  .footer {
    &__navigation {
      font-size: 13px; }
    &__bottom {
      font-size: 14px;
      &-left {
        max-width: 360px; }
      &-title {
        font-size: 25px; } }
    &__form {
      margin-right: 25px; } } }

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {

  .animated {
    transition-property: none !important;
    transform: none !important;
    animation: none !important; }

  body {
    background-size: auto 309px;
    background-position: center 48px; }
  .content {
    &__top {
      max-width: 450px;
      min-height: 90px;
      margin: 0 auto 80px;
      &-left {
        width: 93px;
        left: 0px; }
      &-right {
        right: 0px;
        width: 80px; }
      &-title {
        padding-left: 108px;
        padding-right: 80px;
        font-size: 39px;
        line-height: 43px; }
      &-untitle {
        font-size: 15px;
        top: 95px;
        line-height: 19px; }
      &--all {
        .content__top {
          &-title {
            br {
              display: block; } }
          &-untitle {
            padding-left: 0;
            text-align: center;
            top: 94px;
            br {
              display: block; } } } } }
    &__greeting {
      padding: 0;
      margin-bottom: 65px;
      &-img1, &-img2 {
        width: 227px;
        left: 50%;
        transform: translateX(-100%); }
      &-img2 {
        transform: translateX(0%);
        display: block; }
      &-text {
        width: 100%;
        float: none;
        margin-top: 202px; } }
    &__about {
      &-text {
        font-size: 16px;
        padding-left: 33px;
        padding-right: 33px;
        p {
          line-height: 26px; } }
      &-title {
        font-size: 20px; }
      &-gallery {
        &>div {
          width: 50%; } } }
    &__news {
      margin: 0 -15px;
      &--list {
        .content__news {
          &-block {
            display: block; }
          &-wrapper {
            display: block; }
          &-img {
            width: 210px;
            margin: 0 auto 15px; }
          &-text {
            padding: 0 25px; } } } }
    &__thumb {
      float: none;
      max-width: none;
      width: 100%;
      margin: 0 auto 20px;
      img {
        margin: 0 auto; } }
    &__gallery {
      &--document {
        text-align: center;
        &>div {
          width: 100% !important;
          &+div {
            padding-top: 15px; } } }
      .gallery-item {
        display: inline-block; } } }
  .navigation {
    &__btn {
      display: block; }
    &__wrapper {
      position: fixed;
      top: 0px;
      padding-top: 55px;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-blue;
      z-index: -1;
      display: none;
      &.is-active {
        display: block; }
      &>ul {
        height: 100%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center
        &>li {
          width: 100%;
          display: block;
          &>a, &>span {
            display: block;
            padding: 0;
            height: 35px;
            line-height: 35px; }
          &>ul {
            position: relative;
            left: auto;
            top: auto;
            width: 100%;
            padding: 0 0 25px 0;
            &>li {
              &>a,&>span {
                text-align: center; } } } } } } }
  .header {
    &__search {
      margin-top: -59px; } }
  .footer {
    &__form, &__img {
      display: none; }
    &__bottom {
      padding-top: 0;
      padding-bottom: 50px !important;
      &-left {
        padding: 0;
        max-width: 350px;
        margin: 0 auto;
        float: none; } }
    &__navigation {
      text-align: center;
      padding-bottom: 40px;
      &-wrapper {
        position: fixed;
        top: 0px;
        padding-top: 55px;
        left: 0;
        right: 0;
        bottom: 0;
        background: $color-blue;
        z-index: -1;
        display: none;
        &.is-active {
          display: block; } }
      &-btn {
        display: inline-block; }
      ul {
        height: 100%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        li {
          width: 100%;
          display: block;
          &>a, &>span {
            display: block;
            padding: 0;
            height: 35px;
            line-height: 35px; } } } } } }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 479px) {
  .content {
    padding-top: 20px;
    &__greeting {
      &-img1, &-img2 {
        width: 145px; }
      &-text {
        margin-top: 128px; } }
    &__top {
      margin-bottom: 20px;
      padding-top: 100px;
      &-left, &-right {
        top: 0;
        left: 50%;
        transform: translateX(-100%); }
      &-right {
        transform: translateX(30px); }
      &-title {
        position: inherit;
        padding-left: 0;
        padding-right: 0;
        text-align: center; }
      &-untitle {
        position: inherit;
        top: inherit;
        margin-top: 10px; }
      &--all {
        margin-bottom: 40px;
        .content__top {
          &-title {
            padding-left: 0;
            padding-right: 0; }
          &-untitle {
            font-size: 15px;
            top: 0; } } } }
    &__news {
      &-title {
        padding-left: 35px;
        padding-right: 15px; }
      &-img {
        width: 120px; }
      &--list {
        margin-left: 0;
        margin-right: 0;
        .content__news-text {
          padding-left: 15px;
          padding-right: 15px; } } }
    &__about {
      &-text {
        padding-top: 40px;
        padding-left: 15px;
        padding-right: 15px; } }
    &__gallery {
      &>div {
        width: 100%; } } }
  .footer {
    &__bottom {
      padding-bottom: 80px; } } }

/* Custom, iPhone Retina */
@media only screen and (max-width : 319px) {
  /**/ }


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  /**/ }

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  /**/ }

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .container {
    width: 750px; } }

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .container {
    width: 970px; } }

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .container {
    width: 1170px; } }

/* 1440 */
@media only screen and (min-width : 1440px) {
  /**/ }

@media only screen and (max-width : 1600px) {
  /**/ }

/* 1920 */
@media only screen and (min-width : 1920px) {
  /**/ }
